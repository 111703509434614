<template>
    <div>
        <div class="drag">
            <el-input v-model="form.T_forwarding_unit" @change="inputChange" style="width: 300px;" placeholder="请输入发货单位"></el-input>
            <el-input v-model="form.T_consignee_unit" @change="inputChange" style="width: 300px;" placeholder="请输入收货单位"></el-input>
            <el-select v-model="form.T_template" @change="inputChange" placeholder="请选择下载模板" style="width: 300px;">
                <el-option label="仓库" value="0"></el-option>
                <el-option label="车/箱" value="1"></el-option>
            </el-select>
            <el-input type="textarea" v-model="form.T_remark" @change="inputChange" style="width: 300px;" placeholder="请输入备注"></el-input>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        form: {//下载pdf
            type:Object,
            default:{
                T_forwarding_unit:'',
                T_consignee_unit:'',
                T_template:'0',
                T_remark:'',
            },
        },
    },
    data() {
        return {
            
        };
    },
    methods: {
        inputChange(){
            this.$emit('update:form',this.form);
            console.log('失去焦点')
        },
    },
};
</script>

<style scoped>
::v-deep .el-textarea__inner {
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 100%;
    font-size: inherit;
    color: #fff;
    background-color: #183d7d;
    background-image: none;
    border: 1px solid #183d7d;
    border-radius: 4px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
}
::v-deep .el-result__title p {
    color: #5fc5ff;
}
::v-deep .el-result .icon-success {
    fill: #5fc5ff;
}
::v-deep .el-result__subtitle p {
    color: #5fc5ff;
}
.drag {
    width: 515px;
    height: 400px;
    margin: 0 auto;
    background: url(../../assets/img/bg-icon1.png) no-repeat;
    background-size: 100% 90%;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap:20px;
    color:#5fc5ff;
}

.drag-item {
    font-size: 30px;
}
.drag-item:nth-child(1){
    transform: rotate(15deg);
}
.drag-item:nth-child(3){
    transform: rotate(-30deg);
}
.drag-item-kuang {
    width: 100px;
    height: 40px;
    outline: none;
    background: none;
    text-align: center;
    font-size: 30px;
    color:#5fc5ff;
    border: none;
    border: .5px solid rgba(133, 170, 233, 0.5);
    transition: all .2s;
}
.drag-item-kuang:focus{
    border-radius: 20px;
    color:#5fc5ff;
}
</style>